import React from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { add, basket } from "ionicons/icons";
import ContactBox from "../components/ContactBox";
import OrderBox from "../components/OrderBox";

const Contact: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contact</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Contact</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-padding">
          <ContactBox />
          <h1>Details</h1>
          <IonList>
            <IonItem>
              <IonText style={{ fontWeight: 900 }}>Name: </IonText>
              <IonInput value="Joe Person" />
            </IonItem>
          </IonList>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Name: </IonText>
            <IonInput value="Joe Person" />
          </IonItem>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Phone: </IonText>
            <IonInput value="Joe Person" />
          </IonItem>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Email: </IonText>
            <IonInput value="joe@example.com" />
          </IonItem>
          <h1>Orders</h1>
          <OrderBox />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
