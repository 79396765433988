import React from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { add, basket } from "ionicons/icons";
import OrderBox from "../components/OrderBox";
import ProductBox from "../components/ProductBox";

const Order: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Order</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Order</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-padding">
          <OrderBox />
          <IonButton expand="block" color={"success"}>
            Submitted Order
          </IonButton>
          <IonButton expand="block" color={"light"}>
            Mark Paid
          </IonButton>
          <IonButton expand="block" color={"light"}>
            Packed
          </IonButton>
          <IonButton expand="block" color={"light"}>
            Delivered
          </IonButton>
          <h1>Details</h1>
          <p>Order ID: 123456789</p>
          <p>Order Date: 01/01/2021</p>
          <p>Order Status: Submitted</p>
          <p>Order Total: $100.00</p>
          <h1>From Product</h1>
          <ProductBox />
          <h1>Order</h1>
          <ul>
            <li>
              <b>16lb </b> - Sirloin
            </li>
            <li>
              <b>16lb </b> - Roast
            </li>
            <li>
              <b>40lb </b> - Hamburger
            </li>
          </ul>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Order;
