import React from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { pencil } from "ionicons/icons";
import Shop_Product from "./Shop_Product";

const Shop_Farm: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Murman Farm</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Murman Farm</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-padding">
          <div
            style={{
              padding: "30px 0px 0px 0px",
              textAlign: "left",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 140,
                backgroundImage:
                  "url(https://www.w3schools.com/howto/img_snow_wide.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: 6,
                border: "0px solid black",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20,
              }}
            ></div>
          </div>
          <h1>Currently Available</h1>
          <input
            style={{
              width: "100%",
              height: 40,
              borderRadius: 6,
              border: "1px solid #ccc",
              padding: "0px 10px",
              marginBottom: 20,
            }}
            placeholder="Search"
          ></input>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Shop_Product />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Shop_Farm;
