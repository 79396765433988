import { IonIcon } from "@ionic/react";
import { basket } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

export default function ProductBox() {
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        padding: "10px",
        marginBottom: "16px",
        minHeight: 50,
        display: "flex",
        border: "1px solid #ccc",
      }}
      onClick={() => {
        history.push("/product/1");
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          border: "4px solid #555",
          width: "50px",
          height: "50px",
          backgroundImage: "url(/assets/cow.png)",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div style={{ flex: 1, paddingLeft: 8 }}>
        <div style={{ fontWeight: 700 }}>Cow #1</div>
        <div style={{ fontSize: 12, color: "#555" }}>1.5lb Est: 01/01/23</div>
      </div>
      <div>
        <div style={{ fontSize: 20, fontWeight: 900, textAlign: "center" }}>
          20%
        </div>
        <div
          style={{
            fontSize: 10,
            fontWeight: 900,

            borderRadius: 20,
            padding: "4px 8px",
          }}
        >
          <IonIcon style={{ fontSize: 20, marginRight: -2 }} icon={basket} />
          30
        </div>
      </div>
    </div>
  );
}
