import { IonIcon } from "@ionic/react";
import { basket } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

export default function OrderBox() {
  const history = useHistory();

  function Step(options: { complete?: boolean; title?: string }) {
    return (
      <div style={{ flex: 1, textAlign: "center" }}>
        <div
          style={{
            borderRadius: 50,
            border: options.complete ? "4px solid green" : "4px solid #555",
            width: "50px",
            height: "50px",
            backgroundImage: "url(/assets/cow.png)",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            margin: "4px 4px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 12,
            fontWeight: 700,
          }}
        >
          {options.title}
        </div>
      </div>
    );
  }

  function Order() {
    return (
      <div
        style={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          padding: "10px",
          marginBottom: "16px",
          minHeight: 50,
          display: "flex",
          border: "1px solid #ccc",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          history.push("/order/1");
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              border: "4px solid #555",
              width: "50px",
              height: "50px",
              backgroundImage: "url(/assets/cow.png)",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div style={{ flex: 1, paddingLeft: 8 }}>
            <div style={{ fontWeight: 700 }}>Jon Rhoades</div>
            <div style={{ fontSize: 12, color: "#555" }}>Created: 01/01/23</div>
          </div>
          <div style={{ fontSize: 20, fontWeight: 900 }}>$200</div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", padding: "10px 0px" }}>
          <Step complete={true} title="Ordered" />
          <Step title="Paid" />
          <Step title="Packed" />
          <Step title="Delivered" />
        </div>
      </div>
    );
  }

  return Order();
}
