import React from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { pencil } from "ionicons/icons";

const Farm: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Farm Details</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Farm Details</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-padding">
          <div
            style={{
              padding: "30px 0px 10px 0px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 140,
                backgroundImage:
                  "url(https://www.w3schools.com/howto/img_snow_wide.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: 6,
                border: "0px solid black",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 20,
              }}
            ></div>
            <div>
              <IonButton
                size="small"
                color={"light"}
                onClick={() => {
                  window.location.href = "/shop/2";
                }}
              >
                🌍 View Page
              </IonButton>
              <IonButton size="small" color={"light"}>
                🔗 Share Link
              </IonButton>
            </div>
          </div>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Name: </IonText>
            <IonInput value="joe@example.com" />
          </IonItem>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Phone: </IonText>
            <IonInput value="joe@example.com" />
          </IonItem>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Email: </IonText>
            <IonInput value="joe@example.com" />
          </IonItem>
          <IonItem>
            <IonText style={{ fontWeight: 900 }}>Address: </IonText>
            <IonInput value="joe@example.com" />
          </IonItem>
          <div style={{ textAlign: "center", paddingTop: 20 }}>
            <IonButton size="small" color="primary" slot="end">
              Save
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Farm;
