import { IonIcon } from "@ionic/react";
import { basket, call, mail, phoneLandscape } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";

export default function ContactBox() {
  const history = useHistory();

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        padding: "10px",
        marginBottom: "16px",
        minHeight: 50,
        display: "flex",
        border: "1px solid #ccc",
        flexDirection: "column",
      }}
      onClick={() => {
        history.push("/contact/1");
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            borderRadius: "10px",
            border: "4px solid #555",
            width: "50px",
            height: "50px",
            backgroundImage: "url(/assets/person.png)",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div style={{ flex: 1, paddingLeft: 8 }}>
          <div style={{ fontWeight: 700 }}>Joe Person</div>
          <div style={{ fontSize: 12, color: "#555" }}>Clay Center NE</div>
        </div>
        <div></div>
      </div>
      <div style={{ padding: 0, display: "flex", justifyContent: "end" }}>
        {" "}
        <div
          style={{
            fontSize: 10,
            fontWeight: 900,

            borderRadius: 20,
            padding: "4px 8px",
          }}
        >
          <IonIcon style={{ fontSize: 20, marginRight: -2 }} icon={basket} />3
        </div>
        <div
          style={{
            marginRight: 10,
            padding: "4px 8px",
            borderRadius: 10,
          }}
        >
          <IonIcon style={{ fontSize: 20, marginRight: -2 }} icon={call} />
        </div>
        <div
          style={{
            marginRight: 10,
            padding: "4px 8px",
            borderRadius: 10,
          }}
        >
          <IonIcon style={{ fontSize: 20, marginRight: -2 }} icon={mail} />
        </div>
      </div>
    </div>
  );
}
