import React from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { basket } from "ionicons/icons";
import { useHistory } from "react-router";
import OrderBox from "../components/OrderBox";

const Orders: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Orders</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Orders</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="page-padding">
          <OrderBox />
          <OrderBox />
          <OrderBox />
          <OrderBox />
          <OrderBox />
          <OrderBox />
          <IonButton size="large" expand="block">
            <IonIcon icon={basket} />
            Add
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Orders;
