import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  basket,
  cart,
  ellipse,
  fastFood,
  grid,
  leaf,
  nutrition,
  people,
  restaurant,
  square,
  today,
  triangle,
} from "ionicons/icons";
import Tab1 from "./pages/Products";
import Tab2 from "./pages/Orders";
import Tab3 from "./pages/Farm";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Contacts from "./pages/Contacts";
import Farm from "./pages/Farm";
import Order from "./pages/Order";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import Shop_Farm from "./pages/shop/Shop_Farm";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/product/:id">
            <Product />
          </Route>
          <Route exact path="/orders">
            <Orders />
          </Route>
          <Route path="/order/:id">
            <Order />
          </Route>
          <Route exact path="/contacts">
            <Contacts />
          </Route>
          <Route exact path="/contact/:id">
            <Contact />
          </Route>
          <Route path="/farm">
            <Farm />
          </Route>
          <Route exact path="/">
            <Redirect to="/products" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="products" href="/products">
            <IonIcon aria-hidden="true" icon={nutrition} />
            <IonLabel>Products</IonLabel>
          </IonTabButton>
          <IonTabButton tab="orders" href="/orders">
            <IonIcon aria-hidden="true" icon={basket} />
            <IonLabel>Orders</IonLabel>
          </IonTabButton>
          <IonTabButton tab="contacts" href="/contacts">
            <IonIcon aria-hidden="true" icon={people} />
            <IonLabel>Contacts</IonLabel>
          </IonTabButton>
          <IonTabButton tab="farm" href="/farm">
            <IonIcon aria-hidden="true" icon={grid} />
            <IonLabel>Farm</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <Route exact path="/shop/:id">
        <Shop_Farm />
      </Route>
    </IonReactRouter>
  </IonApp>
);

export default App;
