import { IonChip } from "@ionic/react";
import React from "react";

export default function Shop_Product() {
  return (
    <div
      style={{
        display: "flex",
        padding: 12,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
        width: "100%",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          height: 80,
          width: 80,
          border: "0px solid #ccc",
          borderRadius: 6,
          backgroundImage: "url(/assets/cow.png)",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div style={{ flex: 1, paddingLeft: 12 }}>
        <div style={{ fontWeight: 700 }}>Griller Box</div>
        <div style={{ fontSize: 12, color: "#555" }}>
          🟡 Est Date: 01/01/23
          <br />
          15lb
          <br />
          <p>Perfect for grilling out with friends. Contains 24 Hamburgers</p>
        </div>
      </div>
    </div>
  );
}
